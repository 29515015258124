import { computed } from 'vue';
import { useCcstStore } from './../stores/ccst';
import userService from '../services/http/user-service';
import { ref } from 'vue';
import moment from 'moment';

export function useCalculateHarvestSchedule() {

    const harvestRegistrations = ref([]);
    const data = ref([]);
    const total = ref(0);
    const harvestScheduleUsers = ref([]);
    const totals = ref({total: 0});
    const ready = ref(false);

    const ccst = useCcstStore();

    const getHarvestRegistrations = function(user_ids)
    {
        const after = moment().subtract(3, "week").format('YYYY-MM-DD') + ' 00:00:00';
        const before = moment().format('YYYY-MM-DD HH:mm:ss');


        let url = '/harvest-registrations/group-by?user[user_type_id_is_not]=5&0=harvest_assignment_id_is_not_null&after=' + after  + '&before=' + before + '&group-by=content_id,type_id,user_id';
        if (user_ids.length > 0) {
            url += '&user_ids=' + user_ids.join(',');
        }

        return axios.get(url)
        .then(response => {
            harvestRegistrations.value = response.data;

            for (const harvestRegistration of harvestRegistrations.value) {

                if (! (harvestRegistration.content_id in totals.value)) {
                    totals.value[harvestRegistration.content_id] =  {};
                }

                if (! (harvestRegistration.type_id in totals.value[harvestRegistration.content_id])) {
                    totals.value[harvestRegistration.content_id][harvestRegistration.type_id] = 0;
                }

                totals.value[harvestRegistration.content_id][harvestRegistration.type_id] += parseInt(harvestRegistration.amount);
                totals.value.total += parseInt(harvestRegistration.amount);
            }
        });
    };

    const getUsers = function(userIds)
    {
        return userService.get({filters: {id: userIds.join(',')}, includes: ['avatars']}).then(users => {
            harvestScheduleUsers.value = users;
        });
    };

    const userHasContentAndType = function(user, content, type) {
        return (
            'amounts' in user
            && content.id in user.amounts
            && type.id in user.amounts[content.id]
        );
    };

    const calculateHarvestSchedules = function(user_ids = []) {
        return Promise.all([
            ccst.promise,
            getHarvestRegistrations(user_ids),
        ]).then(() => {
            total.value = 0;

            // Get all content ids that are found in harvest registrations.
            let content_ids = _.map(harvestRegistrations.value, 'content_id')
            content_ids = _.uniq(content_ids);

            // Set the used content name as tabs .
            data.value = ccst.contents.filter(content => content_ids.includes(content.id));

            data.value.map(content => {
                let type_ids = _.uniq(_.map(_.filter(harvestRegistrations.value, {'content_id': content.id}), 'type_id'));
                let types = ccst.types.filter(type => type_ids.includes(type.id)).map(type => {
                    type.users = [];
                    type.translatedName = type.translate('name');
                    return type;
                });
                content.types = JSON.parse(JSON.stringify(types));

                return content;
            });

            // Get the user ids that are found in harvest registrations.
            if (user_ids.length > 0) {
                user_ids = _.intersection(_.map(harvestRegistrations.value, 'user_id'), user_ids);

            } else {
                user_ids = _.map(harvestRegistrations.value, 'user_id');
            }
            user_ids = _.uniq(user_ids);

            // Get the users that are found in the harvest registrations.
            return getUsers(user_ids).then(() => {

                // Loop trough all harvest registrations.
                for (let harvestRegistration of harvestRegistrations.value) {
                    // Find the user that belongs to the current harvest registrations.
                    let user = harvestScheduleUsers.value.firstWhere('id', harvestRegistration.user_id);

                    // If the user was not found(deactivated?) continue to the next harvest regstration.
                    if (! user) {
                        continue;
                    }

                    // If it's the first time the user is found, then set some initial values.
                    if (! ('total' in user)) {
                        user.total = 0;
                        user.amounts = {};
                    }
                    // If it's the first time for the user the content of the harvest registration is found.
                    // Then set the content id in the amounts to the current user.
                    if (! (harvestRegistration.content_id in user.amounts)) {
                        user.amounts[harvestRegistration.content_id] = {};
                    }

                    // If it's the first time for the user, that the type in combination
                    // with the content of the harvest registration is found.
                    // Then: set the content id in the amounts to the current user.
                    if (! (harvestRegistration.type_id in user.amounts[harvestRegistration.content_id])) {
                        user.amounts[harvestRegistration.content_id][harvestRegistration.type_id] = 0;
                    }

                    // Add the amount harvested of the current harvest registration
                    // to the total of the users content and type.
                    user.amounts[harvestRegistration.content_id][harvestRegistration.type_id] += parseInt(harvestRegistration.amount);

                    // Add the amount harvested of the current harvest registration to the total of the user.
                    user.total += parseInt(harvestRegistration.amount);
                }

                for (let content of data.value) {

                    for (let type of content.types) {
                        type.total = 0;

                        for (let user of harvestScheduleUsers.value) {

                            let amount = 0;
                            if (userHasContentAndType(user, content, type)) {
                                amount = (user.amounts[content.id][type.id] / user.total);
                                amount *= 100;
                            }

                            type.users.push({
                                user: user,
                                amount: (['purple_whitepoint', 'tyger'].includes(type.name) ? Math.round(amount * 10) / 10 : (Math.round(amount * 1) / 1)),
                            });

                            type.total += amount;
                        }


                        type.users = _.orderBy(type.users, ['amount'], ['asc']);
                    }

                }
                ready.value = true;
            });
        });
    };


    return {
        // Variables
        harvestRegistrations, data, total, harvestScheduleUsers, totals, ready,

        // Methods
        getHarvestRegistrations, getUsers, userHasContentAndType, calculateHarvestSchedules
    };
}
